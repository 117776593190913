import { PropsWithChildren } from 'react'
import Modal from 'react-bootstrap/Modal'

import './ModalBlock.scss'

export interface ModalBlockProps {
  show: boolean
  onHide: () => void
  className?: string
  classNameHeader?: string
  style?: React.CSSProperties
}

const ModalBlock = ({
  className,
  classNameHeader,
  children,
  ...props
}: PropsWithChildren<ModalBlockProps>) => (
  <Modal
    {...props}
    centered
    className={className}
    aria-labelledby="contained-modal-title-vcenter"
  >
    <Modal.Header closeButton className={classNameHeader} />
    <Modal.Body>{children}</Modal.Body>
  </Modal>
)

export default ModalBlock
