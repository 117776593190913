import Button from '~/components/buttons/Button'
import { useOffline } from '~/hooks'
import { cx } from '~/utils'

import * as containerStyles from './OfflineNotification.module.scss'

const OfflineNotification = () => {
  const { offline, onClose } = useOffline()

  if (!offline) return <></>

  return (
    <div
      className={cx(containerStyles.offlineNotificaton, {
        [containerStyles.offlineNotificaton__withCookie]:
          !localStorage.cookiesAccept,
      })}
    >
      <div className={containerStyles.offlineNotificaton__header}>
        <p>No Internet Connection</p>
        <Button
          isEdgesGrey
          type="button"
          name="Close"
          onClick={onClose}
          customClassName={containerStyles.offlineNotificaton__button}
        >
          ✖︎
        </Button>
      </div>
      <p className={containerStyles.offlineNotificaton__text}>
        Some parts of this site may be unavailable until you come back online.
      </p>
    </div>
  )
}

export default OfflineNotification
