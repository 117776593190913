import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { merge } from '~/utils'

class Axios {
  private readonly config: AxiosRequestConfig
  private instance: any

  constructor(config: AxiosRequestConfig) {
    this.config = config
    this.instance = axios.create(config)
  }

  update(config: AxiosRequestConfig) {
    this.instance = axios.create(merge(this.config, config))
  }

  /**
   * Set instance default header
   *
   * @param header header name
   * @param value header value
   */
  setHeader(header, value) {
    this.instance.defaults.headers.common[header] = value
  }

  getBaseURL() {
    return this.instance.defaults.baseURL
  }

  appendErrorInterceptor = (interceptor) => {
    this.instance.interceptors.response.use((r) => r, interceptor)
  }

  makeRequest(method: string, url: string, options: AxiosRequestConfig = {}) {
    const headers = { ...this.instance.defaults.headers, ...options.headers }

    return this.instance({
      ...options,
      method,
      url,
      headers,
    })
      .then((response: AxiosResponse) => response?.data)
      .catch(({ response }: AxiosError) => Promise.reject(response?.data))
  }

  get = (url: string, config?: AxiosRequestConfig) =>
    this.makeRequest('get', url, config)

  post = (url: string, config: AxiosRequestConfig) =>
    this.makeRequest('post', url, config)

  put = (url: string, config: AxiosRequestConfig) =>
    this.makeRequest('put', url, config)

  patch = (url: string, config: AxiosRequestConfig) =>
    this.makeRequest('patch', url, config)

  del = (url: string, config?: AxiosRequestConfig) =>
    this.makeRequest('delete', url, config)

  requestHandlers = {
    get: this.get,
    post: this.post,
    put: this.put,
    patch: this.patch,
    del: this.del,
  }
}

export default Axios
