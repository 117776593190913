import { ReactNode, RefObject } from 'react'

import { cx } from '~/utils'

import * as containerStyles from './Button.module.scss'

type ButtonProps = {
  id?: string
  ref?: RefObject<HTMLButtonElement>
  type: 'button' | 'submit' | 'reset'
  name?: string
  dataId?: string | undefined
  tabIndex?: number
  loading?: boolean
  disabled?: boolean
  children?: ReactNode
  customClassName?: string
  isRed?: boolean
  isEdgesRed?: boolean
  isEdgesGrey?: boolean
  isDarkBlue?: boolean
  isTransparent?: boolean
  onClick?: () => void
  onKeyDown?: () => void
}

const Button = ({
  id,
  ref,
  type,
  name,
  dataId,
  loading,
  tabIndex,
  disabled,
  children,
  customClassName,
  isRed,
  isEdgesRed,
  isEdgesGrey,
  isDarkBlue,
  isTransparent,
  onClick,
  onKeyDown,
}: ButtonProps) => (
  <button
    id={id}
    ref={ref}
    type={type}
    data-id={dataId}
    name={name}
    tabIndex={tabIndex}
    aria-label={`Button ${name}`}
    disabled={disabled}
    onClick={onClick}
    onKeyDown={onKeyDown}
    className={cx(
      customClassName,
      containerStyles.button,
      isRed && containerStyles.button__red,
      isEdgesRed && containerStyles.button__edgesRed,
      isEdgesGrey && containerStyles.button__edgesGrey,
      isDarkBlue && containerStyles.button__darkBlue,
      isTransparent && containerStyles.button__transparent,
    )}
  >
    {loading ? (
      <div className={containerStyles.button__loader} />
    ) : (
      children || name
    )}
  </button>
)

export default Button
