import { useRef } from 'react'

import ContactFormSection from '../../shared/ContactFormSection'
import ModalBlock from '../../shared/ModalBlock'

export interface ContactFormModalProps {
  show: boolean
  onHide: () => void
}

const ContactFormModal = ({ show, onHide }: ContactFormModalProps) => {
  const mountedModal = useRef(false)

  if (show) {
    mountedModal.current = true
  }

  return (
    <ModalBlock
      style={{
        maxWidth: 1150,
        maxHeight: 900,
        width: '100%',
        height: '100%',
        padding: 0,
      }}
      show={show}
      onHide={onHide}
    >
      {mountedModal.current && (
        <ContactFormSection
          isModal
          title="Let’s build a great custom web product together!"
        />
      )}
    </ModalBlock>
  )
}

export default ContactFormModal
