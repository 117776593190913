import { NestedCommentProp } from '~/types/common-props'
import { RestComment } from '~/types/strapi-rest'

export const nestComments = (comments: RestComment[]): NestedCommentProp[] => {
  const commentList = JSON.parse(JSON.stringify(comments)) as RestComment[]
  const commentMap = {}

  commentList.forEach((comment) => {
    commentMap[comment.id] = comment
  })

  commentList.forEach((comment) => {
    if (comment.attributes.parent_comment_id) {
      const parent = commentMap[comment.attributes.parent_comment_id]
      ;(parent.replies = parent.replies || []).push(comment)
    }
  })

  return commentList.filter((comment) => !comment.attributes.parent_comment_id)
}
