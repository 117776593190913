type Value = string | number | boolean | undefined | null
type Mapping = Record<string, unknown>
interface ReadonlyArgumentArray extends ReadonlyArray<Argument> {}
type Argument = Value | Mapping | ArgumentArray | ReadonlyArgumentArray
interface ArgumentArray extends Array<Argument> {}

export function cx(...args: ArgumentArray): string {
  return args
    .flat()
    .flatMap((x: Argument) => {
      if (typeof x === 'string') {
        return x
      }

      if (typeof x === 'object' && x !== null) {
        return Object.entries(x)
          .filter(([_, condition]) => condition)
          .map(([key]) => key)
      }
    })
    .join(' ')
    .trim()
}
