import React from 'react'

import { cx } from '~/utils'

import * as containerStyles from './EmptyFallback.module.scss'

type EmptyFallbackProps = {
  width?: React.CSSProperties['width']
  height?: React.CSSProperties['height']
  minWidth?: React.CSSProperties['minWidth']
  minHeight?: React.CSSProperties['minHeight']
  className?: string
}

const EmptyFallback = ({ className, ...props }: EmptyFallbackProps) => (
  <div
    className={cx(containerStyles.skeleton, className)}
    style={{ width: '100%', ...props }}
  />
)

export default EmptyFallback
