import { useEffect, useRef } from 'react'

export const useAddClassNameAfterMount = <TElement extends Element>(
  {
    targetClassName,
    loadedClassName,
    enabled = true,
  }: { targetClassName: string; loadedClassName: string; enabled?: boolean },
  deps = [],
) => {
  const ref = useRef<TElement | null>(null)

  useEffect(() => {
    if (targetClassName && loadedClassName && enabled) {
      const elements = ref.current?.querySelectorAll(`.${targetClassName}`)

      if (elements && elements.length) {
        elements.forEach((el) => {
          el.classList.add(loadedClassName)
        })
      }
    }
  }, [enabled, ...deps])

  return ref
}
