import { PropsWithChildren } from 'react'
import { ToastContainer } from 'react-toastify'

import { PageContext } from '~/contexts/PageContext'
import { useModal } from '~/hooks'

import ContactFormModal from '../modals/ContactFormModal'
import CookieNotification from '../notifications/CookieNotification'
import OfflineNotification from '../notifications/OfflineNotification'

const PageLayout = ({ children }: PropsWithChildren) => {
  const { open, toggleModal, hideModal } = useModal()

  return (
    <>
      <PageContext.Provider value={{ toggleModal, hideModal }}>
        {children}

        <ContactFormModal show={open} onHide={toggleModal} />
      </PageContext.Provider>

      <CookieNotification />

      <OfflineNotification />

      <ToastContainer />
    </>
  )
}

export default PageLayout
