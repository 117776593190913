import Axios from '~/services/Axios'
import {
  ChatMessage,
  RestArticle,
  RestChatHistory,
  RestChatrequest,
  RestComment,
  RestLead,
  RestLeadmagnet,
  RestRating,
  RestSubscription,
  RestConsultation,
} from '~/types/strapi-rest'

interface ApiResponse<T = any> {
  data: T
  meta?: Record<string, any>
  error?: Record<string, any>
}

class Strapi extends Axios {
  /**
   * Create new subscription
   */
  createSubscription = (data): Promise<ApiResponse<RestSubscription>> =>
    this.post('/subscriptions', { data: { data } })

  /**
   * Create new leadmagnet
   */
  createLeadmagnets = (data): Promise<ApiResponse<RestLeadmagnet>> =>
    this.post('/leadmagnets', { data: { data } })

  /**
   * Create new lead
   */
  createLead = (data): Promise<ApiResponse<RestLead>> =>
    this.post('/leads', { data: { data } })

  /**
   * Create new consultation
   */
  createConsultation = (data): Promise<ApiResponse<RestConsultation>> =>
    this.post('/consultations', { data: { data } })

  /**
   * Create new chatrequest
   */
  createChatrequest = (data): Promise<ApiResponse<RestChatrequest>> =>
    this.post('/chatrequests', { data: { data } })

  /**
   * Upload data
   */
  sendUpload = (data): Promise<ApiResponse> =>
    this.post('/upload', {
      data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })

  /**
   * Create new comment
   */
  createComment = (articleId, data): Promise<ApiResponse<RestComment>> =>
    this.post(`/articles/${articleId}/comment`, { data })

  /**
   * Get article by id
   */
  getArticleById = (articleId): Promise<ApiResponse<RestArticle>> =>
    this.get(`/articles/${articleId}`, {
      params: { 'populate[0]': 'comments', 'populate[1]': 'ratings' },
    })

  /**
   * Update article by id
   */
  incrementArticleViews = (articleId): Promise<ApiResponse> =>
    this.get(`/articles/${articleId}/views`)

  /**
   * Set article rating
   */
  setArticleRating = (data): Promise<ApiResponse<RestRating>> =>
    this.post('/ratings', { data: { data } })

  /**
   * Create resume
   */
  createResume = (data): Promise<ApiResponse> =>
    this.post('/resumes', { data: { data } })

  /**
   * Get answer from chatbot
   */
  getAnswer = (data: { question: string; sessionId: string }) =>
    fetch(`${this.getBaseURL()}/chat-bot`, {
      method: 'POST',
      body: JSON.stringify({ data }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })

  /**
   * Get session id
   */
  getSessionId = (): Promise<ApiResponse<{ sessionId: string }>> =>
    this.get('/chat-bot/session')

  /**
   * Save to chat history
   */
  saveToChatHistory = (
    sessionId: string,
    messages: ChatMessage[],
  ): Promise<void> =>
    this.post(`/chat-bot/${sessionId}`, { data: { data: { messages } } })

  /**
   * Get chat history
   */
  getChatHistory = (sessionId: string): Promise<ApiResponse<RestChatHistory>> =>
    this.get(`/chat-bot/${sessionId}`)
}

const StrapiApi = new Strapi({
  baseURL: process.env.GATSBY_STRAPI_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export default StrapiApi
