import { object, string } from 'yup'

import { email, name, phone, phoneResume } from '~/validation/validation.fields'

const resume = object().shape({
  name,
  email,
  phone_number: phoneResume,
  last_name: name,
  candidatesource: string().required('This field is required'),
  vacancy: string().required('This field is required'),
})

const contact = object().shape({
  name,
  email,
  phone,
  budget: string().required('This field is required'),
})

const chatRequest = object().shape({
  name,
  email,
  question: string().required('This field is required'),
})

const subscribeSm = object().shape({
  email,
})

const subscribe = object().shape({
  name,
  email,
})

const consultation = object().shape({
  name,
  email,
})

const download = object().shape({
  name,
  email,
})

const comment = object().shape({
  name,
  comment: string().required('This field is required'),
})

export default {
  resume,
  contact,
  chatRequest,
  consultation,
  subscribeSm,
  subscribe,
  download,
  comment,
}
