import { useCallback } from 'react'
import { useInView } from 'react-intersection-observer'

import { useAddClassNameAfterMount } from './useAddClassNameAfterMount'

export const useAddClassNameWhenVisible = <TElement extends Element>({
  targetClassName,
  loadedClassName,
}: {
  targetClassName: string
  loadedClassName: string
}) => {
  const { inView, ref } = useInView({ rootMargin: '100px', triggerOnce: true })

  const containerRef = useAddClassNameAfterMount<TElement>({
    targetClassName,
    loadedClassName,
    enabled: inView,
  })

  const setRefs = useCallback((node: TElement | null) => {
    ref(node)
    containerRef.current = node
  }, [])

  return { ref: setRefs }
}
