import { throttle } from 'lodash'
import { useEffect, useRef, useState } from 'react'

export const useIdle = (ms = 1000 * 60): boolean => {
  const [idle, setIdle] = useState(false)
  const timeoutIdRef = useRef<number | undefined>()

  useEffect(() => {
    const handleTimeout = () => {
      setIdle(true)
    }

    const handleEvent = throttle(() => {
      setIdle(false)

      window.clearTimeout(timeoutIdRef.current)
      timeoutIdRef.current = window.setTimeout(handleTimeout, ms)
    }, 500)

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        handleEvent()
      } else {
        window.clearTimeout(timeoutIdRef.current)
      }
    }

    timeoutIdRef.current = window.setTimeout(handleTimeout, ms)

    window.addEventListener('mousedown', handleEvent)
    window.addEventListener('resize', handleEvent)
    window.addEventListener('keydown', handleEvent)
    window.addEventListener('touchstart', handleEvent)
    window.addEventListener('wheel', handleEvent)
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      window.removeEventListener('mousedown', handleEvent)
      window.removeEventListener('resize', handleEvent)
      window.removeEventListener('keydown', handleEvent)
      window.removeEventListener('touchstart', handleEvent)
      window.removeEventListener('wheel', handleEvent)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.clearTimeout(timeoutIdRef.current)
    }
  }, [ms])

  return idle
}
