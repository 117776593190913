import { string } from 'yup'

export const name = string()
  .required('This field is required')
  .matches(/^[^\s][a-zA-Zа-яА-Я0-9\s]*$/, 'Space not allowed')
  .min(2, 'Must contain at least two letter')

export const email = string()
  .email('Invalid email')
  .required('This field is required')

export const phoneResume = string()

export const phone = string().required('This field is required')
