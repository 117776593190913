import { lazy } from 'react'

import EmptyFallback from '~/components/render-kit/EmptyFallback'
import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'
import ReCaptchaProvider from '~/components/shared/ReCaptchaProvider'

const ContactForm = lazy(() => import('~/components/forms/ContactForm'))

type Props = {
  isModal?: boolean
  isSsr?: boolean
}

const LazyContactForm = ({ isModal, isSsr }: Props) => {
  if (isSsr) {
    return <ContactForm title="Tell us about your project:" isModal={isModal} />
  }

  return (
    <RenderWhenVisible
      withSuspense
      fallback={<EmptyFallback minHeight={563.8} />}
    >
      <ReCaptchaProvider>
        <ContactForm title="Tell us about your project:" isModal={isModal} />
      </ReCaptchaProvider>
    </RenderWhenVisible>
  )
}

export default LazyContactForm
