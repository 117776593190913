exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-approach-tsx": () => import("./../../../src/pages/approach.tsx" /* webpackChunkName: "component---src-pages-approach-tsx" */),
  "component---src-pages-awards-tsx": () => import("./../../../src/pages/awards.tsx" /* webpackChunkName: "component---src-pages-awards-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-case-studies-accommodation-search-website-mvp-index-tsx": () => import("./../../../src/pages/case-studies/accommodation-search-website-mvp/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-accommodation-search-website-mvp-index-tsx" */),
  "component---src-pages-case-studies-collaboration-marketplace-and-smart-contract-system-index-tsx": () => import("./../../../src/pages/case-studies/collaboration-marketplace-and-smart-contract-system/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-collaboration-marketplace-and-smart-contract-system-index-tsx" */),
  "component---src-pages-case-studies-custom-multi-vendor-marketplace-platform-index-tsx": () => import("./../../../src/pages/case-studies/custom-multi-vendor-marketplace-platform/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-custom-multi-vendor-marketplace-platform-index-tsx" */),
  "component---src-pages-case-studies-dental-imaging-management-system-index-tsx": () => import("./../../../src/pages/case-studies/dental-imaging-management-system/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-dental-imaging-management-system-index-tsx" */),
  "component---src-pages-case-studies-fitness-progressive-web-application-index-tsx": () => import("./../../../src/pages/case-studies/fitness-progressive-web-application/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-fitness-progressive-web-application-index-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-case-studies-insurance-progressive-web-application-index-tsx": () => import("./../../../src/pages/case-studies/insurance-progressive-web-application/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-insurance-progressive-web-application-index-tsx" */),
  "component---src-pages-case-studies-intelligent-job-matching-service-index-tsx": () => import("./../../../src/pages/case-studies/intelligent-job-matching-service/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-intelligent-job-matching-service-index-tsx" */),
  "component---src-pages-case-studies-kids-activities-service-marketplace-index-tsx": () => import("./../../../src/pages/case-studies/kids-activities-service-marketplace/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-kids-activities-service-marketplace-index-tsx" */),
  "component---src-pages-case-studies-marketplace-platform-for-online-firearm-sales-index-tsx": () => import("./../../../src/pages/case-studies/marketplace-platform-for-online-firearm-sales/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-marketplace-platform-for-online-firearm-sales-index-tsx" */),
  "component---src-pages-case-studies-marketplace-platform-for-trading-domain-names-index-tsx": () => import("./../../../src/pages/case-studies/marketplace-platform-for-trading-domain-names/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-marketplace-platform-for-trading-domain-names-index-tsx" */),
  "component---src-pages-case-studies-multi-vendor-vehicle-marketplace-index-tsx": () => import("./../../../src/pages/case-studies/multi-vendor-vehicle-marketplace/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-multi-vendor-vehicle-marketplace-index-tsx" */),
  "component---src-pages-case-studies-news-aggregator-index-tsx": () => import("./../../../src/pages/case-studies/news-aggregator/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-news-aggregator-index-tsx" */),
  "component---src-pages-case-studies-online-marketplace-for-boats-index-tsx": () => import("./../../../src/pages/case-studies/online-marketplace-for-boats/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-online-marketplace-for-boats-index-tsx" */),
  "component---src-pages-case-studies-online-marketplace-for-caravans-index-tsx": () => import("./../../../src/pages/case-studies/online-marketplace-for-caravans/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-online-marketplace-for-caravans-index-tsx" */),
  "component---src-pages-case-studies-online-marketplace-for-collectibles-index-tsx": () => import("./../../../src/pages/case-studies/online-marketplace-for-collectibles/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-online-marketplace-for-collectibles-index-tsx" */),
  "component---src-pages-case-studies-recruitment-progressive-web-application-index-tsx": () => import("./../../../src/pages/case-studies/recruitment-progressive-web-application/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-recruitment-progressive-web-application-index-tsx" */),
  "component---src-pages-case-studies-saas-platform-for-bakery-business-index-tsx": () => import("./../../../src/pages/case-studies/saas-platform-for-bakery-business/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-saas-platform-for-bakery-business-index-tsx" */),
  "component---src-pages-case-studies-saas-real-estate-platform-index-tsx": () => import("./../../../src/pages/case-studies/saas-real-estate-platform/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-saas-real-estate-platform-index-tsx" */),
  "component---src-pages-case-studies-timebot-for-slack-index-tsx": () => import("./../../../src/pages/case-studies/timebot-for-slack/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-timebot-for-slack-index-tsx" */),
  "component---src-pages-case-studies-travel-management-platform-index-tsx": () => import("./../../../src/pages/case-studies/travel-management-platform/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-travel-management-platform-index-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contacts-download-mobile-app-development-e-book-index-tsx": () => import("./../../../src/pages/contacts/download-mobile-app-development-e-book/index.tsx" /* webpackChunkName: "component---src-pages-contacts-download-mobile-app-development-e-book-index-tsx" */),
  "component---src-pages-contacts-index-tsx": () => import("./../../../src/pages/contacts/index.tsx" /* webpackChunkName: "component---src-pages-contacts-index-tsx" */),
  "component---src-pages-contacts-thank-you-custom-marketplace-development-checklist-index-tsx": () => import("./../../../src/pages/contacts/thank-you-custom-marketplace-development-checklist/index.tsx" /* webpackChunkName: "component---src-pages-contacts-thank-you-custom-marketplace-development-checklist-index-tsx" */),
  "component---src-pages-contacts-thank-you-custom-marketplace-development-guide-index-tsx": () => import("./../../../src/pages/contacts/thank-you-custom-marketplace-development-guide/index.tsx" /* webpackChunkName: "component---src-pages-contacts-thank-you-custom-marketplace-development-guide-index-tsx" */),
  "component---src-pages-contacts-thank-you-progressive-web-apps-pwa-development-ebook-index-tsx": () => import("./../../../src/pages/contacts/thank-you-progressive-web-apps-pwa-development-ebook/index.tsx" /* webpackChunkName: "component---src-pages-contacts-thank-you-progressive-web-apps-pwa-development-ebook-index-tsx" */),
  "component---src-pages-contacts-thank-you-top-25-progressive-web-application-pwa-examples-index-tsx": () => import("./../../../src/pages/contacts/thank-you-top-25-progressive-web-application-pwa-examples/index.tsx" /* webpackChunkName: "component---src-pages-contacts-thank-you-top-25-progressive-web-application-pwa-examples-index-tsx" */),
  "component---src-pages-contacts-thanks-index-tsx": () => import("./../../../src/pages/contacts/thanks/index.tsx" /* webpackChunkName: "component---src-pages-contacts-thanks-index-tsx" */),
  "component---src-pages-custom-marketplace-development-checklist-tsx": () => import("./../../../src/pages/custom-marketplace-development-checklist.tsx" /* webpackChunkName: "component---src-pages-custom-marketplace-development-checklist-tsx" */),
  "component---src-pages-cv-thanks-index-tsx": () => import("./../../../src/pages/cv/thanks/index.tsx" /* webpackChunkName: "component---src-pages-cv-thanks-index-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mobile-app-development-e-book-tsx": () => import("./../../../src/pages/mobile-app-development-e-book.tsx" /* webpackChunkName: "component---src-pages-mobile-app-development-e-book-tsx" */),
  "component---src-pages-offline-tsx": () => import("./../../../src/pages/offline.tsx" /* webpackChunkName: "component---src-pages-offline-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-progressive-web-apps-pwa-development-ebook-tsx": () => import("./../../../src/pages/progressive-web-apps-pwa-development-ebook.tsx" /* webpackChunkName: "component---src-pages-progressive-web-apps-pwa-development-ebook-tsx" */),
  "component---src-pages-scam-warning-tsx": () => import("./../../../src/pages/scam-warning.tsx" /* webpackChunkName: "component---src-pages-scam-warning-tsx" */),
  "component---src-pages-services-ai-development-services-index-tsx": () => import("./../../../src/pages/services/ai-development-services/index.tsx" /* webpackChunkName: "component---src-pages-services-ai-development-services-index-tsx" */),
  "component---src-pages-services-automotive-software-development-index-tsx": () => import("./../../../src/pages/services/automotive-software-development/index.tsx" /* webpackChunkName: "component---src-pages-services-automotive-software-development-index-tsx" */),
  "component---src-pages-services-chatgpt-development-services-index-tsx": () => import("./../../../src/pages/services/chatgpt-development-services/index.tsx" /* webpackChunkName: "component---src-pages-services-chatgpt-development-services-index-tsx" */),
  "component---src-pages-services-cloud-application-development-index-tsx": () => import("./../../../src/pages/services/cloud-application-development/index.tsx" /* webpackChunkName: "component---src-pages-services-cloud-application-development-index-tsx" */),
  "component---src-pages-services-cross-platform-app-development-index-tsx": () => import("./../../../src/pages/services/cross-platform-app-development/index.tsx" /* webpackChunkName: "component---src-pages-services-cross-platform-app-development-index-tsx" */),
  "component---src-pages-services-custom-enterprise-software-development-index-tsx": () => import("./../../../src/pages/services/custom-enterprise-software-development/index.tsx" /* webpackChunkName: "component---src-pages-services-custom-enterprise-software-development-index-tsx" */),
  "component---src-pages-services-custom-software-development-index-tsx": () => import("./../../../src/pages/services/custom-software-development/index.tsx" /* webpackChunkName: "component---src-pages-services-custom-software-development-index-tsx" */),
  "component---src-pages-services-devops-index-tsx": () => import("./../../../src/pages/services/devops/index.tsx" /* webpackChunkName: "component---src-pages-services-devops-index-tsx" */),
  "component---src-pages-services-ecommerce-marketplace-development-index-tsx": () => import("./../../../src/pages/services/ecommerce-marketplace-development/index.tsx" /* webpackChunkName: "component---src-pages-services-ecommerce-marketplace-development-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-insurance-software-development-index-tsx": () => import("./../../../src/pages/services/insurance-software-development/index.tsx" /* webpackChunkName: "component---src-pages-services-insurance-software-development-index-tsx" */),
  "component---src-pages-services-mobile-app-development-index-tsx": () => import("./../../../src/pages/services/mobile-app-development/index.tsx" /* webpackChunkName: "component---src-pages-services-mobile-app-development-index-tsx" */),
  "component---src-pages-services-multi-vendor-marketplace-development-index-tsx": () => import("./../../../src/pages/services/multi-vendor-marketplace-development/index.tsx" /* webpackChunkName: "component---src-pages-services-multi-vendor-marketplace-development-index-tsx" */),
  "component---src-pages-services-mvp-app-development-index-tsx": () => import("./../../../src/pages/services/mvp-app-development/index.tsx" /* webpackChunkName: "component---src-pages-services-mvp-app-development-index-tsx" */),
  "component---src-pages-services-mvp-development-index-tsx": () => import("./../../../src/pages/services/mvp-development/index.tsx" /* webpackChunkName: "component---src-pages-services-mvp-development-index-tsx" */),
  "component---src-pages-services-mvp-web-development-index-tsx": () => import("./../../../src/pages/services/mvp-web-development/index.tsx" /* webpackChunkName: "component---src-pages-services-mvp-web-development-index-tsx" */),
  "component---src-pages-services-online-marketplace-development-index-tsx": () => import("./../../../src/pages/services/online-marketplace-development/index.tsx" /* webpackChunkName: "component---src-pages-services-online-marketplace-development-index-tsx" */),
  "component---src-pages-services-online-marketplace-development-marketplace-app-development-index-tsx": () => import("./../../../src/pages/services/online-marketplace-development/marketplace-app-development/index.tsx" /* webpackChunkName: "component---src-pages-services-online-marketplace-development-marketplace-app-development-index-tsx" */),
  "component---src-pages-services-product-discovery-index-tsx": () => import("./../../../src/pages/services/product-discovery/index.tsx" /* webpackChunkName: "component---src-pages-services-product-discovery-index-tsx" */),
  "component---src-pages-services-progressive-web-apps-development-index-tsx": () => import("./../../../src/pages/services/progressive-web-apps-development/index.tsx" /* webpackChunkName: "component---src-pages-services-progressive-web-apps-development-index-tsx" */),
  "component---src-pages-services-quality-assurance-index-tsx": () => import("./../../../src/pages/services/quality-assurance/index.tsx" /* webpackChunkName: "component---src-pages-services-quality-assurance-index-tsx" */),
  "component---src-pages-services-react-native-app-development-index-tsx": () => import("./../../../src/pages/services/react-native-app-development/index.tsx" /* webpackChunkName: "component---src-pages-services-react-native-app-development-index-tsx" */),
  "component---src-pages-services-saas-development-index-tsx": () => import("./../../../src/pages/services/saas-development/index.tsx" /* webpackChunkName: "component---src-pages-services-saas-development-index-tsx" */),
  "component---src-pages-services-ui-ux-design-index-tsx": () => import("./../../../src/pages/services/ui-ux-design/index.tsx" /* webpackChunkName: "component---src-pages-services-ui-ux-design-index-tsx" */),
  "component---src-pages-services-ui-ux-design-website-redesign-services-index-tsx": () => import("./../../../src/pages/services/ui-ux-design/website-redesign-services/index.tsx" /* webpackChunkName: "component---src-pages-services-ui-ux-design-website-redesign-services-index-tsx" */),
  "component---src-pages-services-web-application-development-index-tsx": () => import("./../../../src/pages/services/web-application-development/index.tsx" /* webpackChunkName: "component---src-pages-services-web-application-development-index-tsx" */),
  "component---src-pages-sitemap-index-tsx": () => import("./../../../src/pages/sitemap/index.tsx" /* webpackChunkName: "component---src-pages-sitemap-index-tsx" */),
  "component---src-pages-subscription-complete-index-tsx": () => import("./../../../src/pages/subscription/complete/index.tsx" /* webpackChunkName: "component---src-pages-subscription-complete-index-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-pages-top-25-progressive-web-application-pwa-examples-tsx": () => import("./../../../src/pages/top-25-progressive-web-application-pwa-examples.tsx" /* webpackChunkName: "component---src-pages-top-25-progressive-web-application-pwa-examples-tsx" */),
  "component---src-pages-ultimate-guide-on-how-to-kickstart-online-marketplace-business-tsx": () => import("./../../../src/pages/ultimate-guide-on-how-to-kickstart-online-marketplace-business.tsx" /* webpackChunkName: "component---src-pages-ultimate-guide-on-how-to-kickstart-online-marketplace-business-tsx" */),
  "component---src-templates-ai-chat-tsx": () => import("./../../../src/templates/AIChat.tsx" /* webpackChunkName: "component---src-templates-ai-chat-tsx" */),
  "component---src-templates-blog-article-tsx": () => import("./../../../src/templates/blog/Article.tsx" /* webpackChunkName: "component---src-templates-blog-article-tsx" */),
  "component---src-templates-blog-author-tsx": () => import("./../../../src/templates/blog/Author.tsx" /* webpackChunkName: "component---src-templates-blog-author-tsx" */),
  "component---src-templates-blog-blog-tsx": () => import("./../../../src/templates/blog/Blog.tsx" /* webpackChunkName: "component---src-templates-blog-blog-tsx" */),
  "component---src-templates-blog-categories-tsx": () => import("./../../../src/templates/blog/Categories.tsx" /* webpackChunkName: "component---src-templates-blog-categories-tsx" */),
  "component---src-templates-blog-search-tsx": () => import("./../../../src/templates/blog/Search.tsx" /* webpackChunkName: "component---src-templates-blog-search-tsx" */),
  "component---src-templates-blog-tags-tsx": () => import("./../../../src/templates/blog/Tags.tsx" /* webpackChunkName: "component---src-templates-blog-tags-tsx" */)
}

