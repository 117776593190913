export const merge = (obj, source) => {
  if (obj === source) return obj
  const newValue = {
    ...obj,
    ...source,
  }

  Object.entries(source).forEach(([key, value]) => {
    newValue[key] =
      obj[key] && typeof obj[key] === 'object' ? merge(obj[key], value) : value
  })

  return newValue
}
