import { Link } from 'gatsby'

import FormInfo from '~/components/forms/FormInfo'
import ContactImage from '~/components/shared/ContactFormSection/ContactImage'
import LazyContactForm from '~/components/shared/ContactFormSection/LazyContactForm'
import { cx } from '~/utils'

import * as containerStyles from './ContactFormSection.module.scss'

export interface ContactFormSectionProps {
  title?: string
  widthTitle?: string
  isModal?: boolean
  formInfoClassName?: string
  customSalesInfo?: boolean
  customSalesInfoStepFour?: string
  formKey?: string
  isSsr?: boolean
}

const ContactFormSection = ({
  title,
  widthTitle,
  isModal,
  customSalesInfo,
  customSalesInfoStepFour,
  formInfoClassName,
  formKey = 'contact',
  isSsr,
}: ContactFormSectionProps) => {
  const defaultSalesInfoText = [
    {
      item: '1. Our Sales Manager Taya will be in touch with you within 1 business day.',
    },
    {
      item: '2. We will sign the NDA if required, and start the project discussion.',
    },
    {
      item: '3. Our custom software development experts will analyze your requirements and suggest the best ways to bring your idea to life.',
    },
  ]

  const customSalesInfoText = [
    {
      item: '1. We will contact you within 24 hours to discuss the next steps.',
    },
    {
      item: (
        <>
          2. In the meantime, you can check our
          <Link to="/case-studies/" className="mx5" key="case">
            Case Studies
          </Link>
          and
          <Link to="/blog/" className="ml5" key="blog">
            Blog
          </Link>
          .
        </>
      ),
    },
    {
      item: '3. We will sign the NDA if required and start the project discussion.',
    },
    {
      item: customSalesInfoStepFour,
    },
  ]

  return (
    <div
      className={cx(
        'container container-md',
        containerStyles.contactFormSection,
      )}
    >
      <div className="row">
        {title && (
          <div className="col-12">
            <h2
              className={containerStyles.contactFormSection__title}
              style={{ maxWidth: widthTitle }}
            >
              {title}
            </h2>
          </div>
        )}
        <div className="col-12 col-md-8">
          <LazyContactForm isModal={isModal} key={formKey} isSsr={isSsr} />
        </div>
        <div
          className={cx(
            'col-12 col-md-4',
            containerStyles.contactFormSection__infoBg,
          )}
        >
          <FormInfo
            isSalesInfo
            formInfoClassName={formInfoClassName}
            salesInfoText={
              customSalesInfo ? customSalesInfoText : defaultSalesInfoText
            }
            image={<ContactImage />}
            title="What’s next?"
          />
        </div>
      </div>
    </div>
  )
}

export default ContactFormSection
