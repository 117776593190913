import { useCallback, useState } from 'react'

export const useModal = () => {
  const [open, setOpen] = useState(false)

  const showModal = useCallback(() => setOpen(true), [])
  const hideModal = useCallback(() => setOpen(false), [])
  const toggleModal = useCallback(() => setOpen((prev) => !prev), [])

  return { open, showModal, hideModal, toggleModal }
}
