import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

import QuestionIcon from '~/assets/images/icons/vacancy-question-icon.svg?file'
import { Item } from '~/types/data-array'
import { cx } from '~/utils'

import * as containerStyles from './FormInfo.module.scss'

interface FormInfoProps {
  image: IGatsbyImageData | JSX.Element | undefined
  alt?: string
  title: string
  isHRInfo?: boolean
  isSalesInfo?: boolean
  salesInfoText?: Item[]
  formInfoClassName?: string
}

const FormInfo = ({
  image,
  alt,
  title,
  isHRInfo,
  isSalesInfo,
  salesInfoText,
  formInfoClassName,
}: FormInfoProps) => (
  <div
    className={cx(
      containerStyles.formInfo,
      containerStyles[`${formInfoClassName}`],
    )}
  >
    <div className={containerStyles.formInfo__image}>
      {React.isValidElement(image) ? (
        image
      ) : (
        <GatsbyImage
          image={image as IGatsbyImageData}
          alt={`${alt} | Codica`}
          title={alt}
          loading="lazy"
        />
      )}
    </div>

    {isSalesInfo && (
      <>
        <div className={containerStyles.formInfo__title}>{title}</div>
        <ul className={containerStyles.formInfo__list}>
          {salesInfoText?.map(({ item }) => <li key={String(item)}>{item}</li>)}
        </ul>
      </>
    )}

    {isHRInfo && (
      <div className={containerStyles.formInfo__sectionHr}>
        <img
          src={QuestionIcon}
          alt={`${title} | Codica`}
          title={title}
          width={30}
          height={30}
          loading="lazy"
        />
        <div>
          <div className={containerStyles.formInfo__title}>{title}</div>
          <a
            href="tel:+380502184543"
            className={containerStyles.formInfo__link}
          >
            +380 50 218 45 43
          </a>
          <a
            href="mailto:job@codica.com"
            className={containerStyles.formInfo__link}
          >
            job@codica.com
          </a>
        </div>
      </div>
    )}
  </div>
)

export default FormInfo
