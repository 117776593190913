import type { GatsbyBrowser } from 'gatsby'

import './src/styles/index.scss'

export const onClientEntry: GatsbyBrowser['onClientEntry'] = async () => {
  const IS_SAFARI = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }

  if (IS_SAFARI) {
    const smoothscroll = (await import('smoothscroll-polyfill')).default

    smoothscroll.polyfill()
  }
}

export { wrapPageElement } from './gatsby-shared'
