import React, { ReactNode, Suspense } from 'react'
import { useInView, IntersectionOptions } from 'react-intersection-observer'

type RenderWhenVisibleProps = {
  children: ReactNode
  containerProps?: React.HTMLProps<HTMLDivElement>
  className?: string
  fallback?: ReactNode
  withSuspense?: boolean
  options?: IntersectionOptions
}

const defaultFallback = <></>

const RenderWhenVisible = ({
  children,
  fallback = defaultFallback,
  className,
  containerProps,
  options,
  withSuspense,
}: RenderWhenVisibleProps) => {
  const { inView, ref } = useInView({
    rootMargin: '400px',
    triggerOnce: true,
    ...options,
  })

  return (
    <div
      ref={ref}
      className={className}
      {...containerProps}
      style={{ ...containerProps?.style }}
    >
      {inView ? (
        withSuspense ? (
          <Suspense fallback={fallback}>{children}</Suspense>
        ) : (
          children
        )
      ) : (
        fallback
      )}
    </div>
  )
}

export default RenderWhenVisible
