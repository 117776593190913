import Axios from '~/services/Axios'

const params = { token: `${process.env.GATSBY_IP_TOKEN_KEY}` }

type Location = {
  city: string
  country: string
  ip: string
  [key: string]: any
}

const userLocationCache: {
  data: Location | null
} = { data: null }

class Ipinfo extends Axios {
  /**
   * Get user location
   */
  getUserLocation = async ({ options } = { options: {} }) => {
    if (userLocationCache.data) {
      return userLocationCache.data
    } else {
      userLocationCache.data = (await this.get('/', {
        params,
        ...options,
      })) as Location

      return userLocationCache.data
    }
  }
}

const IpinfoApi = new Ipinfo({
  baseURL: 'https://ipinfo.io',
  headers: {
    'Content-Type': 'application/json',
  },
})

export default IpinfoApi
