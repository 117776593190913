import Axios from '~/services/Axios'

const params = { api_token: process.env.GATSBY_PIPEDRIVE_API_TOKEN }

class Pipedrive extends Axios {
  sendPerson = (data) =>
    this.post(`/api/v1/persons`, {
      data,
      params,
    })

  sendDeal = (data) =>
    this.post(`/api/v1/deals`, {
      data,
      params,
    })

  sendNote = (data) =>
    this.post(`/api/v1/notes`, {
      data,
      params,
    })
}

const PipedriveApi = new Pipedrive({
  baseURL: process.env.GATSBY_PIPEDRIVE_API,
  headers: {
    'Content-Type': 'application/json',
  },
})

export default PipedriveApi
