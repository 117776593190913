import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

interface ReCaptchaProviderProps {
  children: React.ReactNode
}

const ReCaptchaProvider = ({ children }: ReCaptchaProviderProps) => (
  <GoogleReCaptchaProvider
    useRecaptchaNet
    reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTHA_SITE_KEY as string}
    scriptProps={{ async: true, defer: true, appendTo: 'body' }}
  >
    {children}
  </GoogleReCaptchaProvider>
)

export default ReCaptchaProvider
