import { WithRequired } from '~/types/helper'

type MetaData =
  | Queries.STRAPI_PAGE
  | Queries.STRAPI_CASE
  | Queries.STRAPI_SERVICE
  | Queries.STRAPI_PRIVACYPOLICY

export const getPageMeta = (data: Queries.Maybe<MetaData>) =>
  data as WithRequired<MetaData, 'meta_title' | 'og_image'>
