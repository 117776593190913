import { Link } from 'gatsby'
import { useEffect, useState } from 'react'

import Button from '~/components/buttons/Button'

import * as containerStyles from './CookieNotification.module.scss'

const CookieNotification = () => {
  const [showNotification, setShowNotification] = useState(false)

  const handleClick = () => {
    localStorage.setItem('cookiesAccept', JSON.stringify(true))
    setShowNotification(false)
  }

  useEffect(() => {
    if (!localStorage.cookiesAccept) {
      const handleScroll = () => {
        if (window.scrollY >= 700) {
          setShowNotification(true)
          window.removeEventListener('scroll', handleScroll)
        }
      }

      window.addEventListener('scroll', handleScroll, { passive: true })
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (!showNotification) return <></>

  return (
    <section className={containerStyles.cookiesSection}>
      <div className={containerStyles.cookiesSection__wrapper}>
        <div className={containerStyles.cookiesSection__text}>
          We track sessions with cookies.
          <Link
            to="/privacy-policy/"
            className={containerStyles.cookiesSection__link}
          >
            Privacy Policy
          </Link>
          .
        </div>
        <Button
          isEdgesGrey
          type="button"
          name="OK"
          onClick={handleClick}
          customClassName={containerStyles.cookiesSection__btn}
        />
      </div>
    </section>
  )
}

export default CookieNotification
