import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'

import { PhotosQuery } from '~/types/graphql'
import { fileToImageLikeData } from '~/utils'

const ContactImage = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      salesManagerPhoto: file(
        relativePath: { eq: "company-photo/sales-manager-photo.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  const salesManagerPhoto = getImage(
    fileToImageLikeData(query.salesManagerPhoto),
  )

  return (
    <GatsbyImage
      image={salesManagerPhoto as IGatsbyImageData}
      alt="Talk to the Sales Manager | Codica"
      title="Talk to a Codica Sales Manager"
      loading="lazy"
    />
  )
}

export default ContactImage
