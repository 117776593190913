import { useCallback, useEffect, useState } from 'react'

export const useOffline = () => {
  const [offline, setOffline] = useState(false)

  const onClose = () => {
    setOffline(false)
    window.localStorage.setItem('offlineNotificaton', JSON.stringify(true))
  }

  const handleOffline = useCallback(() => {
    if (!navigator.onLine) {
      if (!window.localStorage.getItem('offlineNotificaton')) {
        setOffline(true)
      }
    } else {
      setOffline(false)
      window.localStorage.removeItem('offlineNotificaton')
    }
  }, [])

  useEffect(() => {
    handleOffline()
    window.addEventListener('online', handleOffline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOffline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [handleOffline])

  return { offline, onClose }
}
